<template>
  <div>
    <v-navigation-drawer v-model="drawer" app clipped disable-resize-watcher>
      <v-list dense v-for="i in headerItems" :key="i.id">
        <v-list-item title :to="i.to">
          <v-list-item-action>
            <v-icon>{{ i.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ i.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list dense>
        <v-list-item to="/auth/sign-out">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>サインアウト</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app dense dark :color="mainColor">
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="d-flex flex-row" link="/home">
        <v-avatar
          height="32px"
          width="32px"
          min-width="32px"
          @click.stop="drawer = !drawer"
        >
          <img src="../assets/header-logo.png" />
        </v-avatar>
        <h1 class="title ml-1">{{ siteName }}</h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items
        class="hidden-sm-and-down"
        v-for="i in headerItems"
        :key="i.title"
      >
        <v-btn text :to="i.to">{{ i.title }}</v-btn>
      </v-toolbar-items>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-menu offset-y>
          <template v-slot:activator="{ on: on }">
            <v-btn icon v-on="on"><v-icon>mdi-account</v-icon></v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ account.username }}</v-list-item-title>
                <v-list-item-subtitle>{{ accountGroup }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ account.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/auth/sign-out">
              <v-list-item-action>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>サインアウト</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { SITE_NAME, MAIN_COLOR } from '@/constants'

export default {
  name: 'Header',
  data() {
    return {
      drawer: false,
      siteName: SITE_NAME,
      mainColor: MAIN_COLOR,
      items: [
        {
          title: 'TOP',
          to: '/home',
          icon: 'mdi-home',
          type: false,
          isAdminOnly: false
        },
        {
          title: 'センサ',
          to: '/device',
          icon: 'mdi-remote',
          type: false,
          isAdminOnly: false
        },
        {
          title: 'ユーザー',
          to: '/user',
          icon: 'mdi-account-group',
          type: false,
          isAdminOnly: false
        },
        {
          title: '管理ユーザー',
          to: '/admin-user',
          icon: 'mdi-account-circle',
          type: false,
          isAdminOnly: false
        },
        {
          title: 'グループ',
          to: '/group',
          icon: 'mdi-domain',
          type: false,
          isAdminOnly: false
        },
        {
          title: 'メッセージ',
          to: '/message',
          icon: 'mdi-send',
          type: false,
          isAdminOnly: false
        },
        {
          title: 'ログ',
          to: '/log',
          icon: 'mdi-code-not-equal',
          type: false,
          isAdminOnly: false
        },
        {
          title: '設定',
          to: '/setting',
          icon: 'mdi-cog-outline',
          type: false,
          isAdminOnly: true
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['account', 'accountGroup', 'isAdminGroup']),
    headerItems() {
      if (this.isAdminGroup) {
        return this.items
      }
      return this.items.filter(i => !i.isAdminOnly)
    }
  },
  methods: {
    ...mapActions(['setFullscreenLoading'])
  }
}
</script>
