<template>
  <v-app>
    <app-header></app-header>
    <router-view />
    <v-dialog v-model="dialog" persistent width="300">
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      ></v-progress-linear>
    </v-dialog>
    <app-footer></app-footer>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AppHeader from '@/components/app-header.vue'
import AppFooter from '@/components/app-footer.vue'

export default {
  name: 'PageIndex',
  components: {
    AppHeader,
    AppFooter
  },
  computed: {
    ...mapGetters(['fullscreenLoading']),
    dialog: {
      get() {
        return this.fullscreenLoading || false
      },
      set(value) {
        this.setFullscreenLoading = value
      }
    }
  },
  methods: {
    ...mapActions(['setFullscreenLoading'])
  }
}
</script>
